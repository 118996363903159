import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { getToken } from '@/auth/utils'
import axios from 'axios'
import Vue from 'vue'
import router from '../../../../../router'

export default function useInvoicesList() {
  const toast = useToast()
  const refInvoiceListTable = ref(null)
  const refInvoiceListTableConfirmed = ref(null)
  const refInvoiceListTableUnconfirmed = ref(null)
  const refInvoiceListTableEmpty = ref(null)
  const tableColumns = [
    { key: 'no', sortable: false },
    { key: 'customer', sortable: false },
    { key: 'job_no', sortable: false },
    { key: 'inv_no', sortable: false },
    { key: 'inv_date', sortable: false },
    { key: 'no_inv_pusat', sortable: false },
    { label: 'Inv Pusat Date', key: 'no_inv_pusat_date', sortable: false },
    { label: 'Support Doc', key: 'supporting_doc', sortable: false },
    { key: 'actions' },
  ]
  const tableColumnsConfirmed = [
    { key: 'no', sortable: false },
    { key: 'customer', sortable: false },
    { key: 'job_no', sortable: false },
    { key: 'inv_no', sortable: false },
    { key: 'inv_date', sortable: false },
    { key: 'no_inv_pusat', sortable: false },
    { label: 'Inv Pusat Date', key: 'no_inv_pusat_date', sortable: false },
    { label: 'Support Doc', key: 'supporting_doc', sortable: false },
    { key: 'actions' },
  ]
  const tableColumnsUnconfirmed = [
    { key: 'no', sortable: false },
    { key: 'customer', sortable: false },
    { key: 'job_no', sortable: false },
    { key: 'inv_no', sortable: false },
    { key: 'inv_date', sortable: false },
    { key: 'no_inv_pusat', sortable: false },
    { label: 'Inv Pusat Date', key: 'no_inv_pusat_date', sortable: false },
    { label: 'Support Doc', key: 'supporting_doc', sortable: false },
    { key: 'actions' },
  ]
  const tableColumnsEmpty = [
    { key: 'no', sortable: false },
    { key: 'customer', sortable: false },
    { key: 'job_no', sortable: false },
    { key: 'inv_no', sortable: false },
    { key: 'inv_date', sortable: false },
    { key: 'no_inv_pusat', sortable: false },
    { label: 'Inv Pusat Date', key: 'no_inv_pusat_date', sortable: false },
    { label: 'Support Doc', key: 'supporting_doc', sortable: false },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const perPageConfirmed = ref(10)
  const totalItemsConfirmed = ref(0)
  const currentPageConfirmed = ref(1)
  const searchQueryConfirmed = ref('')
  const perPageUnconfirmed = ref(10)
  const totalItemsUnconfirmed = ref(0)
  const currentPageUnconfirmed = ref(1)
  const searchQueryUnconfirmed = ref('')
  const perPageEmpty = ref(10)
  const totalItemsEmpty = ref(0)
  const currentPageEmpty = ref(1)
  const searchQueryEmpty = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const statusFilter = ref(null)
  const branchFilterAll = ref('')
  const branchFilterUnconfirmed = ref('')
  const branchFilterConfirmed = ref('')
  const branchFilterEmpty = ref('')
  const startDateFilter = ref('')
  const endDateFilter = ref('')
  const startDateFilterConfirmed = ref('')
  const endDateFilterConfirmed = ref('')
  const startDateFilterUnconfirmed = ref('')
  const endDateFilterUnconfirmed = ref('')
  const startDateFilterEmpty = ref('')
  const endDateFilterEmpty = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refInvoiceListTable.value ? refInvoiceListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const dataMetaConfirmed = computed(() => {
    const localItemsCountConfirmed = refInvoiceListTableConfirmed.value ? refInvoiceListTableConfirmed.value.localItems.length : 0
    return {
      from: perPageConfirmed.value * (currentPageConfirmed.value - 1) + (localItemsCountConfirmed ? 1 : 0),
      to: perPageConfirmed.value * (currentPageConfirmed.value - 1) + localItemsCountConfirmed,
      of: totalItemsConfirmed.value,
    }
  })

  const dataMetaUnconfirmed = computed(() => {
    const localItemsCountUnconfirmed = refInvoiceListTableUnconfirmed.value ? refInvoiceListTableUnconfirmed.value.localItems.length : 0
    return {
      from: perPageUnconfirmed.value * (currentPageUnconfirmed.value - 1) + (localItemsCountUnconfirmed ? 1 : 0),
      to: perPageUnconfirmed.value * (currentPageUnconfirmed.value - 1) + localItemsCountUnconfirmed,
      of: totalItemsUnconfirmed.value,
    }
  })

  const dataMetaEmpty = computed(() => {
    const localItemsCountEmpty = refInvoiceListTableEmpty.value ? refInvoiceListTableEmpty.value.localItems.length : 0
    return {
      from: perPageEmpty.value * (currentPageEmpty.value - 1) + (localItemsCountEmpty ? 1 : 0),
      to: perPageEmpty.value * (currentPageEmpty.value - 1) + localItemsCountEmpty,
      of: totalItemsEmpty.value,
    }
  })

  const refetchData = () => {
    refInvoiceListTable.value.refresh()
  }

  const refetchDataConfirmed = () => {
    refInvoiceListTableConfirmed.value.refresh()
  }

  const refetchDataUnconfirmed = () => {
    refInvoiceListTableUnconfirmed.value.refresh()
  }

  const refetchDataEmpty = () => {
    refInvoiceListTableEmpty.value.refresh()
  }

  watch([branchFilterAll, branchFilterConfirmed, branchFilterUnconfirmed, branchFilterEmpty, currentPage, perPage, searchQuery, roleFilter, statusFilter, sortBy, isSortDirDesc, currentPageConfirmed, perPageConfirmed, searchQueryConfirmed, startDateFilter, endDateFilter, startDateFilterConfirmed, endDateFilterConfirmed, currentPageUnconfirmed, perPageUnconfirmed, searchQueryUnconfirmed, startDateFilterUnconfirmed, endDateFilterUnconfirmed, currentPageEmpty, perPageEmpty, searchQueryEmpty, startDateFilterEmpty, endDateFilterEmpty], () => {
    refetchData()
    refetchDataConfirmed()
    refetchDataUnconfirmed()
    refetchDataEmpty()
  })

  const fetchInvoices = (ctx, callback) => {
    const userToken = getToken()
    const userRole = Vue.$cookies.get('UserRole')
    let userKodeUnitKerja = userRole !== 'super-admin' && userRole !== 'admin' && userRole !== 'finance' ? Vue.$cookies.get('KodeUnitKerja') : ''
    if (branchFilterAll.value !== '') {
      userKodeUnitKerja = branchFilterAll.value
    }
    const headers = {
      'Content-Type': 'application/json',
      'X-Token-Access': `Bearer ${userToken}`,
    }
    const to = perPage.value * (currentPage.value - 1)
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_INVOICE}/getcorporate?unit_kerja=${userKodeUnitKerja}&limit=${perPage.value}&offset=${to}&search=${searchQuery.value}&type=all&date_start=${startDateFilter.value}&date_end=${endDateFilter.value}`, { headers })
      .then(response => {
        totalItems.value = response.data.total_count
        callback(response.data.Payload)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }

  const fetchInvoicesConfirmed = (ctx, callback) => {
    const userToken = getToken()
    const userRole = Vue.$cookies.get('UserRole')
    let userKodeUnitKerja = userRole !== 'super-admin' && userRole !== 'admin' && userRole !== 'finance' ? Vue.$cookies.get('KodeUnitKerja') : ''
    if (branchFilterConfirmed.value !== '') {
      userKodeUnitKerja = branchFilterConfirmed.value
    }
    const headers = {
      'Content-Type': 'application/json',
      'X-Token-Access': `Bearer ${userToken}`,
    }
    const toConfirmed = perPageConfirmed.value * (currentPageConfirmed.value - 1)
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_INVOICE}/getcorporate?unit_kerja=${userKodeUnitKerja}&limit=${perPageConfirmed.value}&offset=${toConfirmed}&search=${searchQueryConfirmed.value}&type=confirmed&date_start=${startDateFilterConfirmed.value}&date_end=${endDateFilterConfirmed.value}`, { headers })
      .then(response => {
        totalItemsConfirmed.value = response.data.total_count
        callback(response.data.Payload)
      })
      .catch(error => {
        console.log(error)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching data list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchInvoicesUnconfirmed = (ctx, callback) => {
    const userToken = getToken()
    const userRole = Vue.$cookies.get('UserRole')
    let userKodeUnitKerja = userRole !== 'super-admin' && userRole !== 'admin' && userRole !== 'finance' ? Vue.$cookies.get('KodeUnitKerja') : ''
    if (branchFilterUnconfirmed.value !== '') {
      userKodeUnitKerja = branchFilterUnconfirmed.value
    }
    const headers = {
      'Content-Type': 'application/json',
      'X-Token-Access': `Bearer ${userToken}`,
    }
    const toUnconfirmed = perPageUnconfirmed.value * (currentPageUnconfirmed.value - 1)
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_INVOICE}/getcorporate?unit_kerja=${userKodeUnitKerja}&limit=${perPageUnconfirmed.value}&offset=${toUnconfirmed}&search=${searchQueryUnconfirmed.value}&type=unconfirmed&date_start=${startDateFilterUnconfirmed.value}&date_end=${endDateFilterUnconfirmed.value}&in_queue=true`, { headers })
      .then(response => {
        totalItemsUnconfirmed.value = response.data.total_count
        callback(response.data.Payload)
      })
      .catch(error => {
        console.log(error)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching data list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchInvoicesEmpty = (ctx, callback) => {
    const userToken = getToken()
    const userRole = Vue.$cookies.get('UserRole')
    let userKodeUnitKerja = userRole !== 'super-admin' && userRole !== 'admin' && userRole !== 'finance' ? Vue.$cookies.get('KodeUnitKerja') : ''
    if (branchFilterEmpty.value !== '') {
      userKodeUnitKerja = branchFilterEmpty.value
    }
    const headers = {
      'Content-Type': 'application/json',
      'X-Token-Access': `Bearer ${userToken}`,
    }
    const toEmpty = perPageEmpty.value * (currentPageEmpty.value - 1)
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_INVOICE}/getcorporate?unit_kerja=${userKodeUnitKerja}&limit=${perPageEmpty.value}&offset=${toEmpty}&search=${searchQueryEmpty.value}&type=empty&date_start=${startDateFilterEmpty.value}&date_end=${endDateFilterEmpty.value}&void=true`, { headers })
      .then(response => {
        totalItemsEmpty.value = response.data.total_count
        callback(response.data.Payload)
      })
      .catch(error => {
        console.log(error)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching data list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'super-admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'super-admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    branchFilterAll,
    branchFilterConfirmed,
    branchFilterUnconfirmed,
    branchFilterEmpty,
    fetchInvoices,
    fetchInvoicesConfirmed,
    fetchInvoicesUnconfirmed,
    fetchInvoicesEmpty,
    tableColumns,
    tableColumnsConfirmed,
    tableColumnsUnconfirmed,
    tableColumnsEmpty,
    perPageConfirmed,
    currentPageEmpty,
    currentPageConfirmed,
    totalItemsConfirmed,
    totalItemsEmpty,
    perPageUnconfirmed,
    perPageEmpty,
    currentPageUnconfirmed,
    totalItemsUnconfirmed,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    dataMetaConfirmed,
    dataMetaUnconfirmed,
    dataMetaEmpty,
    perPageOptions,
    searchQueryConfirmed,
    searchQueryUnconfirmed,
    searchQueryEmpty,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refInvoiceListTable,
    refInvoiceListTableConfirmed,
    refInvoiceListTableUnconfirmed,
    refInvoiceListTableEmpty,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
    refetchDataConfirmed,
    refetchDataUnconfirmed,
    refetchDataEmpty,
    // Extra Filters
    roleFilter,
    statusFilter,
    startDateFilter,
    endDateFilter,
    startDateFilterConfirmed,
    endDateFilterConfirmed,
    startDateFilterUnconfirmed,
    endDateFilterUnconfirmed,
    startDateFilterEmpty,
    endDateFilterEmpty,
  }
}
